/*------------------------------------*\
	Articles
\*------------------------------------*/

.category-nav {
    position: relative;
    margin-bottom: $spacing-l;

    &__inner {
        display: flex;
        justify-content: center;
    }

    &__list {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        flex-wrap: nowrap;
        gap: $spacing-s;

        @include bp(bp3) {
            flex-direction: row;
            gap: $spacing-l;
        }
    }

    &__link {
        line-height: 1.3;
        border-bottom: 0;
        @include font-size(16px);

        @include bp(bp3) {
            @include font-size(18px);
        }
    }

    &__link--active {
        border-bottom: 2px solid $primary;
        font-weight: bold;
    }    
}

.article-list {
    display: flex;
    flex-direction: column;
	gap: $spacing;
	margin: 0 auto;
	padding: $spacing;
	list-style: none;
    width: 100%;
    max-width: $bp5;

    @include bp(bp5) {
        display: grid;
        grid-gap: $spacing-l;
        grid-template-columns: repeat(6, 1fr);
    }
}

.article-list--related {
	grid-template-columns: repeat(3, 1fr);
    
    .article-list__item {
        grid-column: auto;
    }
}

.article-list__item {
    grid-column: span 6;
    margin: 0;
}

.article-list__item--featured {
    grid-column: span 2;
}



.article-list--featured-1 {
    .article-list__item--1 {
        grid-column: span 6;
    }
}

.article-list--featured-2 {
    .article-list__item--1,
    .article-list__item--2 {
        grid-column: span 3;
    }
}

.article-list--featured-4 {
    .article-list__item--1,
    .article-list__item--2,
    .article-list__item--3,
    .article-list__item--4 {
        grid-column: span 3;
    }
}

.article-list--featured-5 {
    .article-list__item--1,
    .article-list__item--2 {
        grid-column: span 3;
    }
}

.post-author {
    margin-top: auto;
    display: flex;
    align-items: center;
    gap: $spacing-s;

    &__name {
        font-size: $font-size-xxsmall;
    }

    img {
        border-radius: 50%;
        border: 2px solid white;
        height: 40px;
        width: 40px;
    }
}

.post-categories {
    margin: 0 0 $spacing 0;
    padding: 0;
    
    &__item {
        background: white;
        font-size: $font-size-xxsmall;
        color: $font-color-light;
        border: 0;
        border-radius: 4px;
        padding: 4px 6px;
        line-height: 1;

        &:hover {
            color: $font-color;
        }
    }
}

.post-categories--card {
    .post-categories__item {
        background: $light-grey;
        font-size: $font-size-xxsmall;
        color: $font-color-light;
        border: 0;
        border-radius: 4px;
        padding: 4px 6px;

        &:hover {
            color: $font-color;
        }
    }
}

.post-categories--alt {
    .post-categories__item {
        background: transparent;
        font-size: $font-size-xxsmall;
        color: $primary;
        border: 0;
        border-radius: 4px;
        padding: 0;
    }
}

.pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
	margin-top: $spacing-l;

    .page-numbers {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: $font-size-xxsmall;
        padding: 5px;
        border: 0;
        height: 34px;
        width: 34px;
        border-radius: 4px;
    }

    .page-numbers.current {
        background: $primary;
        color: white;
        font-weight: bold;
    }

    .page-numbers.prev,
    .page-numbers.next {
        width: auto;
    }
}

.article {
    &__header {
        width: 100%;
        margin: 1rem auto 0;
        padding: $spacing;
        max-width: $bp5;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    &__back-btn {
        border: 0;

        svg {
            height: 10px;
            width: 10px;
        }
    }

    &__title {
        font-size: 30px;
        font-weight: 900;
        margin-top: 0.25rem;
        margin-bottom: 0.25em;
    
        @include bp(bp3) {
            font-size: 40px;
        }

        @include bp(bp6) {
            font-size: 54px;
        }
    }

    &__subtitle {
        margin-top: 0;
        font-size: 22px;
        font-weight: 400;
    
        @include bp(bp3) {
            font-size: 26px;
        }
    
        @include bp(bp6) {
            font-size: 30px;
        }
    }

    &__meta {
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: $spacing-s;
        margin-top: $spacing-s;

        @include bp(bp4) {
            gap: $spacing;
            flex-direction: row;
        }
    }

    &__date {
        font-size: $font-size-xxsmall;
        color: $font-color-light;
        margin: 0;
        
        span {
            display: block;
        }

        @include bp(bp4) {
            span {
                display: inline-block;
                margin-right: 1rem;
            }
        }
    }

    &__media {
        display: block;
        width: 100%;
        margin: $spacing-l auto 0;
        max-width: 1000px;
        border-radius: 40px;
    }

    .post-categories {
        flex-basis: 100%;
        margin-bottom: 0;
    }
}

.post-navigation {
    margin: $spacing-l 0;
    
    &__item {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        margin-bottom: $spacing;
    }

    &__item span {
        text-transform: uppercase;
        font-size: $font-size-xxsmall;
    }

    a {
        font-weight: 700;
    }
}

.author {
	background: white;
	padding: 1.5em;
	margin-top: 2em;
	border-radius: 20px;

    @include bp(bp5) {
        display: flex;
    }

    &__title {
        margin: 0;
        @include font-size(24px);
    }

    &__media {
        margin-right: 1.5rem;
        margin-bottom: 1rem;
    
        @include bp(bp5) {
            margin-bottom: 0;
        }
    }

    &__media img {
        border-radius: 50%;
    }

    &__bio {
        margin: 0.5rem 0 0;
        font-size: $font-size-small;
        line-height: 1.5;
    }
}

.related-articles {
	margin-top: 4em;
}
