/*------------------------------------*\
	Footer
\*------------------------------------*/

.site-footer {
    margin-top: $spacing-xxl;
    padding-bottom: $spacing;
    font-size: $font-size-xsmall;

    &__inner {
        border-top: 1px solid #ccced1;
        padding-top: $spacing-s;
    }

    &__copyright,
    &__details {
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 1rem;
        line-height: 1.3;
        margin-top: 1rem;
        color: $font-color-light;
    }

    a {
        border-color: transparent;

        &:hover {
            border-color: $primary;
        }
    }

    a[aria-current] {
        border-color: $primary;
    }

    @include bp(bp4) {
        &__copyright,
        &__details {
            flex-direction: row;
            padding-left: 0;
            padding-right: 0;
        }

        &__details {
            flex-direction: row;
        }
    }

    @include bp(bp6) {
        padding-bottom: $spacing-l;
        
        &__inner {
            display: flex;
            justify-content: space-between;
        }

        &__details {
            justify-content: flex-end;
            flex-direction: row;
        }
    }
}
