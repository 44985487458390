/*------------------------------------*\
	Quotes
\*------------------------------------*/

blockquote {
	margin: 0 0 $spacing;
	padding: 0 $spacing;
	border-left: 4px solid $primary;
}

blockquote footer {
	color: #999;
	@include font-size(14px);
}
