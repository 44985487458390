/*------------------------------------*\
	Mixins
\*------------------------------------*/

/**
 * Convert pixels to REMs with fallback for older browsers
 * Example usage:
 * @include font-size(14px)
 */
@mixin font-size($size) {
	font-size: $size;
	font-size: ($size / $base-font-size) * 1rem;
}



/**
 * @2x mixin
 * Example usage:
 * @include image-2x("logo2x.png", 100px, 25px);
 */
@mixin image-2x($image, $width, $height) {
  @media (min--moz-device-pixel-ratio: 1.3),
         (-o-min-device-pixel-ratio: 2.6/2),
         (-webkit-min-device-pixel-ratio: 1.3),
         (min-device-pixel-ratio: 1.3),
         (min-resolution: 1.3dppx) {
    /* on retina, use image that's scaled by 2 */
    background-image: url($image);
    background-size: $width $height;
  }
}



/**
 * Add a breakpoint
 * Example usage:
 * @include bp(bp2) { // css }
 */
@mixin bp($size) {
	@if $size == bp2 {
		@media (min-width: $bp2) { @content; }
	}
	@else if $size == bp3 {
		@media (min-width: $bp3) { @content; }
	}
	@else if $size == bp4 {
		@media (min-width: $bp4) { @content; }
	}
	@else if $size == bp5 {
		@media (min-width: $bp5) { @content; }
	}
	@else if $size == bp6 {
		@media (min-width: $bp6) { @content; }
	}
	@else if $size == bp7 {
		@media (min-width: $bp7) { @content; }
	}
}



/**
 * Clearfix
 * Example usage:
 * @include clearfix;
 */
@mixin clearfix {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}


/**
 * Create a breakpoint for below a given size 
 * Example usage:
 * @include below(200px) { // css }
 */
@mixin below($size) {
	@media (max-width: $size) { @content; }
}



/**
 * Create a breakpoint for above a given size 
 * Example usage:
 * @include above(200px) { // css }
 */
@mixin above($size) {
	@media (min-width: $size) { @content; }
}