/*------------------------------------*\
	Convert Kit
\*------------------------------------*/

.formkit-form {
    [data-style="clean"] {
        padding: 0!important;
    }

    .formkit-fields {
        display: flex;
        align-items: center;
    }

    .formkit-field {
        margin: 0!important;
    }

    .formkit-submit {
        background: white!important;
        margin-top: 10px!important;
        color: $font-color!important;

        span {
            padding: 6px 20px!important;
        }
    }

    @include bp(bp4) {
        .formkit-submit {
            margin: 0 0 0 10px!important;
        }
    }
}
