.gallery {
    margin-left: auto;
    margin-right: auto;
    max-width: $bp5;
	margin-top: 2rem;
	margin-bottom: 2rem;

	@include bp(bp4) {
		margin-top: 4rem;
		margin-bottom: 4rem;
	}
}

.gallery__container {
	display: flex;
	flex-direction: column;
	grid-gap: 2rem;

	@include bp(bp4) {
		display: grid;
		grid-template-columns: repeat(6, 1fr);
		grid-template-rows: repeat(2, 250px);
	}
}

.gallery__item--1 {
	@include bp(bp4) {
		grid-column: 1 / 5;
		grid-row: 1 / 3;
	}
}

.gallery__item--2 {
	@include bp(bp4) {
		grid-column: 5 / 7;
		grid-row: 1;
	}
}

.gallery__item--3 {
	@include bp(bp4) {
		grid-column: 5 / 7;
		grid-row: 2;
	}
}

.gallery__item:last-child {

	@include bp(bp4) {
        justify-self: center;
        grid-column-start: span 2;
    }
}


.gallery--reverse {
	.gallery__item--1 {
        @include bp(bp4) {
			grid-column: 3 / 7;
			grid-row: 1 / 3;
		}
	}

	.gallery__item--2 {
		@include bp(bp4) {
			grid-column: 1 / 3;
			grid-row: 1;
		}
	}

	.gallery__item--3 {
		@include bp(bp4) {
			grid-column: 1 / 3;
			grid-row: 2;
		}
	}
}

.gallery__image {
	@include bp(bp4) {
		object-fit: cover;
		width: 100%;
		height: 100%;
		border-radius: 20px;
	}
}

.gallery__caption {
    width: 100%;
    max-width: $bp3;
    margin-left: auto;
    margin-right: auto;
    padding: 1rem;

    p {
        font-size: $font-size-small;
        color: #666;
        border-bottom: 1px solid #e8e9ea;
    }
}


.gallery--single .gallery__container {
	.gallery__item--1 {
        @include bp(bp4) {
			grid-column: 1 / 7;
			grid-row: 1 / 3;
		}
	}
}
