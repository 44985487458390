/*------------------------------------*\
	Clients bar
\*------------------------------------*/

.brag-bar {
	margin: 8rem 0;
	text-align: center;

	img {
		display: block;
		margin: $spacing auto;
		height: 50px;
		transition: all 0.2s linear;

		&:hover {
			filter: grayscale(100%);
		}

		@include bp(bp3) {
			display: inline;
			margin: 0 $spacing $spacing $spacing;
		}
	}
}
