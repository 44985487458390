.card {	
	display: flex;
    flex-direction: column;
	position: relative;
	background: white;
	height: 100%;
	border-radius: 20px;
	position: relative;
	transition: border 0.3s ease-in-out;

    &__media a {
        display: block;
        border: 0;
        width: 100%;
    }

	&__media img {
		border-radius: 20px 20px 0 0;
        width: 100%;
	}

	&:hover, &:active, &:focus {
		text-decoration: none;
	}

    &__inner {
        padding: 1rem;
    
        @include bp(bp4) {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            flex: 1;
            padding: 1.25rem;
        }
    }


    &__title {
        font-size: $h4-size;
        line-height: 1.2em;
        margin: 0;
        font-weight: 700;

        a {
            text-decoration: none;
            border: none;
        }
    }

    &__excerpt p {
        color: $font-color-light;
        font-size: $font-size-xsmall;
        line-height: 1.4em;
        margin: $spacing-s 0 $spacing;
    }
}

.card--flat {
    flex-direction: row;
    margin: 0 auto;

	.card__media {
        display: none;

        @include bp(bp4) {
            display: block;
            flex-basis: 250px;
        }

        @include bp(bp4) {
            flex-basis: 300px;
        }
	}

    .card__media a {
        display: block;
        height: 100%;
        width: 100%;
        border: 0;
    }

	.card__media img {
        display: block;
		border-radius: 20px 0 0 20px;
        object-fit: cover;
        height: 100%;
        width: 100%;
	}

    .card__inner {
        padding: 0.75rem 1.25rem;
    }

    .card__title {
        font-size: $h5-size;
    }

    .post-author {
        // display: none;
    }

    .post-categories {
        margin-bottom: 0.5rem;
    }
}
