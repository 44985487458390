/*------------------------------------*\
	Global
\*------------------------------------*/

$debug:                 false;
$responsive-helper:     false;

$spacing:           	1rem;
$spacing-l:         	$spacing*2;
$spacing-xl:        	$spacing*3;
$spacing-xxl:        	$spacing*4;
$spacing-xxxl:        	$spacing*5;
$spacing-s:         	$spacing/2;
$spacing-xs:        	$spacing/3;

$border-radius:         10px;
$border-radius-large:   32px;
