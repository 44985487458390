@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700;900&display=swap');

/*------------------------------------*\
	Settings
	Global variables, config
\*------------------------------------*/
@import "settings/global";
@import "settings/colors";
@import "settings/type";
@import "settings/responsive";



/*------------------------------------*\
	Grid system
\*------------------------------------*/
@import 'grid/mixins.scss';
@import 'grid/grid.scss';



/*------------------------------------*\
	Tools
	Mixins and functions
\*------------------------------------*/
@import "tools/mixins";
@import "tools/placeholders";
@import "tools/animations";



/*------------------------------------*\
	Generic
	Ground-zero styles
\*------------------------------------*/
@import "generic/normalize";
@import "generic/box-sizing";



/*------------------------------------*\
	Base
	Unclassed HTML elements
\*------------------------------------*/
@import "base/font-awesome";
@import "base/figures";
@import "base/forms";
@import "base/headings";
@import "base/images";
@import "base/links";
@import "base/lists";
@import "base/page";
@import "base/paragraphs";
@import "base/quotes";
@import "base/hr";
// @import "base/tables";



/*------------------------------------*\
	Objects
	Cosmetic-free design patterns
\*------------------------------------*/
@import "objects/wrappers";



/*------------------------------------*\
	Vendor
	Third-party styles
\*------------------------------------*/
/*@import "vendor/plugin";*/



/*------------------------------------*\
	Components
	Designed components, chunks of UI
\*------------------------------------*/
@import "components/header";
@import "components/footer";
@import "components/buttons";
@import "components/call-out";
@import "components/testimonials";
@import "components/brag-bar";
@import "components/hero";
@import "components/box";
@import "components/articles";
@import "components/content";
@import "components/grid";
@import "components/hamburger";
@import "components/ck";
@import "components/gallery";
@import "components/card";
@import "components/series-nav";
@import "components/glossary";



/*------------------------------------*\
	Trumps
	Helpers and overrides
\*------------------------------------*/
@import "trumps/helper";



/*------------------------------------*\
	Media
	Print styles
\*------------------------------------*/
@media print {
	@import "media/print";
}
