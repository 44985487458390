/*------------------------------------*\
	Figure
\*------------------------------------*/

figure {
	margin: 0;
}

figcaption {
	margin-top: $spacing-s;
}

figcaption p {
	font-size: 80%;
}
