/*------------------------------------*\
	Hero
\*------------------------------------*/

.hero {
    position: relative;
    margin-bottom: $spacing-xl;
    padding: $spacing-xl $spacing;
    text-align: center;
}

.hero--home {
    padding-bottom: 8em;
    margin-bottom: 0;
}

.hero--single {
    min-height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.hero__inner {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    mix-blend-mode: multiply;
    background: $primary;
}

.hero__content {
    position: relative;
    z-index: 2;
}

.hero h1 {
    margin: 0;
    font-size: 30px;
    font-weight: 900;
    margin-bottom: 0.25em;
    color: white;

    @include bp(bp4) {
        font-size: 54px;
    }

    small {
        color: $grey;
    }
}

.hero h2 {
    margin: $spacing-s 0 0 0;
    font-weight: 400;

    @include font-size(24px);
}

.hero--large h1 {
    text-align: left;

    @include font-size($h2-size);
}

.hero--large p {
    margin: $spacing-s 0 0 0;
    color: #e1e1e1;
    text-align: left;
    font-weight: 400;
    font-family: $primary-font;

    @include font-size(18px);
}
