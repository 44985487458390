/*------------------------------------*\
	Forms
\*------------------------------------*/

// Gravity forms
.gform_wrapper.gravity-theme {
    margin-bottom: 2rem!important;

    .gfield_label {
        font-size: 20px!important;
        line-height: 1;
        margin-bottom: 0!important;
    }

    .gfield_error .gfield_label {
        color: $font-color;
    }

    .gfield_description {
        color: #666;
        margin: 0!important;
        padding: 0!important;
    }

    .gfield {
        margin-bottom: 1rem;
    }

    .ginput_container {
        margin-top: 0.5rem;
    }

    .ginput_container {
        input, 
        textarea,
        select {
            padding: 6px 12px;
            border: 1px solid #ccc;
            border-radius: 4px;

            &:focus {
                border: 1px solid $primary;
                outline: 0;
            }
        }
    }

    .ginput_container {
        select {
            height: 46px;
            appearance: none;
            background: white;
            background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/PjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+PHN2ZyB2ZXJzaW9uPSIxLjEiIGlkPSJDYXBhXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IiB3aWR0aD0iMjU1cHgiIGhlaWdodD0iMjU1cHgiIHZpZXdCb3g9IjAgMCAyNTUgMjU1IiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAyNTUgMjU1OyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+PGc+PGcgaWQ9ImFycm93LWRyb3AtZG93biI+PHBvbHlnb24gcG9pbnRzPSIwLDYzLjc1IDEyNy41LDE5MS4yNSAyNTUsNjMuNzUgIi8+PC9nPjwvZz48Zz48L2c+PGc+PC9nPjxnPjwvZz48Zz48L2c+PGc+PC9nPjxnPjwvZz48Zz48L2c+PGc+PC9nPjxnPjwvZz48Zz48L2c+PGc+PC9nPjxnPjwvZz48Zz48L2c+PGc+PC9nPjxnPjwvZz48L3N2Zz4=);
            background-repeat: no-repeat;
            background-position: calc(100% - 10px) center;
            background-size: 14px;
        }
    }

    .gform_footer {
        margin-top: 0!important;
    }

    .gform_button {
        background: $primary;
        border: 0;
        color: white;
        font-weight: bold;
        padding: 0.5rem 1.5rem;
        border-radius: $border-radius;
        cursor: pointer;

        &:hover, &:focus {
            outline: 0;
            background: $primary-dark;
            color: #fff;
            text-decoration: none;
        }
    }

    .gform_validation_errors {
        padding: 0!important;
        background: transparent!important;
        font-size: 18px!important;
        font-weight: bold;
        box-shadow: none!important;
        border-radius: 0!important;
        border: 0!important;
    }

    .gfield_description.validation_message {
        padding: 0;
        border: 0;
        background: transparent;
        font-weight: bold;
    }

    .gfield_required {
        font-style: normal!important;
        text-transform: lowercase;
    }
}
