/*------------------------------------*\
	Box
\*------------------------------------*/

.box .fa {
	position: relative;
	top: 2px;
	margin-right: 5px;

	@include font-size(30px);
}

.home-box-container {
	margin-top: -90px;
}

.home-box {
	margin: 0 1em;
	height: 100%;
	position: relative;
	padding: 20px 20px 70px 20px;
	background: #fff;
	border: 1px solid #f9f9f9;
	border-radius: 4px;
	box-shadow: 0 10px 10px rgba(0,0,0,.06);
	text-align: left;

	h2 {
		@include font-size(24px);
		line-height: 1.2em;
	}

	p {
		@include font-size(17px);
		line-height: 1.4em;
	}

	.btn {
		background: $cta;
		color: #fff;
		margin: 20px auto 0 auto;
		position: absolute;
		bottom: 20px;
		left: 50%;
		transform: translateX(-50%);
	}

	@include bp(bp3) {
		// margin-bottom: 0;
	}
}

