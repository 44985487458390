/*------------------------------------*\
	Testimonials
\*------------------------------------*/

.testimonials {
    position: relative;
}

.swiper-pagination-bullet.swiper-pagination-bullet-active {
    background: $primary!important;
}
