/*------------------------------------*\
	Wrappers
\*------------------------------------*/

.wrapper {
	padding: 0 $spacing;
    margin: 0 auto;

	p:last-child {
        margin-bottom: 0;
    }
}

.wrapper--wide {
    padding: 0 $spacing;
    margin: 0 auto;
    max-width: $bp5;
}

.wrapper--small {
	padding: 0 $spacing;
    margin: 0 auto;
    max-width: $bp3;
}

.wrapper--full {
	margin: $spacing-xl 0;
}

.page-header + .wrapper--full {
    margin-top: 0;
}

.wrapper--img {
    .wrapper {
        position: relative;
    }

    h2 {
        margin-top: 0;
    }

    p:last-child {
        margin-bottom: 0;
    }
}

.col-img {
    float: right;
    width: 125px;

    @include bp(bp3) {
        width: 150px;
    }
    @include bp(bp5) {
        position: absolute;
        top: 0;
        left: -150px;
        float: none;
    }
    @include bp(bp6) {
        left: -200px;
        width: 175px;
    }
}
