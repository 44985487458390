/*------------------------------------*\
	Lists
\*------------------------------------*/

/**
 * Ordered list
 */
ol {
	padding-left: $spacing-l;
}


/**
 * Unordered list
 */
ul {
	padding-left: $spacing-l;
}

li {
	margin: $spacing 0;
}



/**
 * Definition list
 */
dt {
	margin: $spacing-l 0 $spacing-s 0;
	font-weight: bold;
	color: $heading-color;
}

dd {
	margin-left: 0;
}