/*------------------------------------*\
	Links
\*------------------------------------*/

a {
	color: $link-color;
	text-decoration: none;
    border-bottom: 2px solid $primary;
	transition: color 0.2s linear;  
}

a:focus,
a:hover {
	color: $primary;
	text-decoration: none;
}
