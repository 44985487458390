/*------------------------------------*\
	Header
\*------------------------------------*/  

.page-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
	position: relative;
	padding: $spacing;

    &__logo {
        flex-basis: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__nav {
        flex-basis: 100%;
    }

    .nav-active &__logo {
        position: relative;
        z-index: 999;
    }

    &__page-header__mobile-btn {
        background: transparent;
        padding-top: 0;
    }

    &__mobile-btn-label {
        text-transform: uppercase;
        margin-right: 4px;
        position: relative;
        top: -2px;
        @include font-size(16px);
    }

    @include bp(bp5) {
        padding: 1.5rem;
        flex-wrap: no-wrap;

        &__logo {
            flex-basis: 180px;
        }
    
        &__nav {
            flex: 1;
        }

        &__mobile-btn {
            display: none;
        }
    }
}

.logo {
	text-indent: -9999px;
	float: left;
	display: block;
	width: 150px;
	height: 37px;
	background: url("../../images/logo.svg") no-repeat center;
    border: 0;

    @include bp(bp5) {
        width: 180px;
        height: 45px;
    }
}




/**
 * Primary nav
 */
.primary-nav {
	display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 998;
    background: $background-color;
	margin: 0;
	padding: 140px $spacing;
	font-family: $primary-font;
    font-size: $h4-size;

	@include bp(bp5) {
		display: block!important;
        position: static;
        width: auto;
        height: auto;
        padding: 0;
        font-size: $font-size-small;
	}

    ul {
        margin: 0;
        padding: 0;
        list-style: none;
        text-align: left;
    
        @include bp(bp5) {
            margin: 0;
            text-align: right;
        }
    }

    li {
        display: block;
        margin: $spacing 0;
        padding: 0;
        position: relative;
    
        @include bp(bp5) {
            display: inline-block;
            margin: 0 0 0 1.25rem;
        }
    }

    a {
        border-bottom: 2px solid transparent;
    
        &:hover {
            border-bottom: 2px solid $primary;
        }
    }

    li.current_page_item a, .single-post .primary-nav li.menu-item-108 a {
        color: $primary;
        border-bottom: 2px solid $primary;
        font-weight: 700;
    }

    li.highlight a {
        background: $primary;
        color: white;
        padding: 10px 20px;
        border-radius: 30px;
        border: 0;
        font-weight: 400;
        transition: background 0.2s ease-in-out;

        &:hover {
            background: $primary-dark;
        }
    }
}

.page-header__nav.active .primary-nav {
    display: block;
}
