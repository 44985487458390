/*------------------------------------*\
	Typography
\*------------------------------------*/

p {
	margin: 0 0 1.6em;
}

p.lead {
	font-size: 130%;
    line-height: 1.4em;
}

.highlight-container, .highlight {
    position: relative;
    font-weight: bold;
}

.highlight-container {
    display: inline-block;
}

.highlight-container:before {
    content: " ";
    display: block;
    height: 90%;
    width: 100%;
    margin-left: -3px;
    margin-right: -3px;
    position: absolute;
    background: $primary-light;
    transform: rotate(1deg);
    top: -1px;
    left: -1px;
    border-radius: 20% 25% 20% 24%;
    padding: 10px 3px 3px 10px;
}
