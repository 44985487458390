/*------------------------------------*\
	Page
\*------------------------------------*/

html,
body {
	color: $font-color;
	font-size: $base-font-size - 1px;
	font-family: $primary-font;
	line-height: $base-line-height;

	@include bp(bp3) {
		font-size: $base-font-size;
	}
}

body {
	background-color: $background-color;
}
