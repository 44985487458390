/*------------------------------------*\
	Colours
\*------------------------------------*/

$background-color:      #fef8f2;

$primary:               #7442e0;
$primary-dark:          #562daf;
$primary-light:         #DAD2EF;

$secondary:             #b4caba;

$dark-blue:				#53705d;

$cta:					#ef8500;

$grey:					#c7c7c7;
$light-grey:			#F5F4F2;

$font-color:            #34363b;
$font-color-light:      #6D6A62;
$heading-color:         #0b0c0d;
$link-color:            #34363b;
$link-color-hover:      $primary-dark;

$validation: 			#e20000;
