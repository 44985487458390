.series-nav {
    position: relative;
    z-index: 2;
    margin: $spacing auto $spacing-xl;

    &__inner {
        background: white;
        border-radius: 20px;
        padding: 1.5rem;
    }

    &__title {
        font-size: $h4-size;
        font-weight: 700;
    }

    &__list {
        margin-bottom: 0;
    }

    &__item {
        margin: 0;
    }

    &__link {
        font-weight: 700;
    }
}

.series-nav--has-thumbnail {
    margin-top: 0;

    @include bp(bp5) {
        margin-top: -100px;
    }
}
