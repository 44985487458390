/*------------------------------------*\
	Responsive
\*------------------------------------*/

$bp2:       30em;
$bp3:       37.5em;
$bp4:       48em;
$bp5:       60em;
$bp6:       76.250em;
$bp7:       87.5em;


$enable-flex: true;
$enable-grid-classes: true;

// Grid breakpoints
// Define the minimum dimensions at which your layout will change, adapting to different screen sizes, for use in media queries.
$grid-breakpoints: (
	xs: 0,
	sm: 576px,
	md: 768px,
	lg: 992px,
	xl: 1200px
) !default;

// Grid containers
// Define the maximum width of `.container` for different screen sizes.
$container-max-widths: (
	sm: 540px,
	md: 720px,
	lg: 960px,
	xl: 1140px
) !default;

// Grid columns
// Set the number of columns and specify the width of the gutters.
$grid-columns: 12 !default;
$grid-gutter-width: 30px !default;
