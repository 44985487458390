/*------------------------------------*\
	Glossary
\*------------------------------------*/

.glossary {
    width: 100%;
    margin: 0 auto;
    padding: $spacing;
    max-width: $bp5;

    @include bp(bp6) {
        display: flex;
        gap: $spacing-xxxl;
    }

    .glossary-nav {
        @include bp(bp6) {
            flex: 0 0 30%;
            order: 2;
        }
    }

    .glossary-list {
        @include bp(bp6) {
            flex: 0 0 70%;
            order: 1;
        }
    }
}

.glossary-nav {
    &__inner {
        position: sticky;
        top: 60px;
        margin-bottom: 2rem;
    }

    &__select {
        height: 46px;
        padding-left: 10px;
        padding-right: 28px;
        border-radius: 8px;
        font-size: 16px;
        appearance: none;
        background: white;
        background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/PjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+PHN2ZyB2ZXJzaW9uPSIxLjEiIGlkPSJDYXBhXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IiB3aWR0aD0iMjU1cHgiIGhlaWdodD0iMjU1cHgiIHZpZXdCb3g9IjAgMCAyNTUgMjU1IiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAyNTUgMjU1OyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+PGc+PGcgaWQ9ImFycm93LWRyb3AtZG93biI+PHBvbHlnb24gcG9pbnRzPSIwLDYzLjc1IDEyNy41LDE5MS4yNSAyNTUsNjMuNzUgIi8+PC9nPjwvZz48Zz48L2c+PGc+PC9nPjxnPjwvZz48Zz48L2c+PGc+PC9nPjxnPjwvZz48Zz48L2c+PGc+PC9nPjxnPjwvZz48Zz48L2c+PGc+PC9nPjxnPjwvZz48Zz48L2c+PGc+PC9nPjxnPjwvZz48L3N2Zz4=);
        background-repeat: no-repeat;
        background-position: calc(100% - 10px) center;
        background-size: 14px;
    }
}

.glossary-entry {
    margin-bottom: $spacing-xxl;

    &__title {
        font-size: $h3-size;
        font-weight: 700;
    }

    &__subtitle {
        font-size: $h4-size;
        font-weight: 700;
    }

    a[href^="#"] {
        border-bottom: 2px dashed $primary;
    }
}

.pull-out {
    // background: white;
    // padding: $spacing;
    // border-radius: 20px;
}

.link-card-list {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
