/*------------------------------------*\
	Grid
\*------------------------------------*/

.grid {
    margin: 0 auto 2rem;
    width: 100%;
    max-width: $bp5;
    padding: 0 1rem;

    &__item {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        position: relative;
        padding: 1rem;
        border-radius: 20px;
        margin-top: 1rem;
    }

    &--white &__item {
        background: white;
    }

    &__item p,
    &__item ul,
    &__item ol {
        font-size: $font-size-small;
    }

    &__item p:last-child {
        margin-bottom: 0;
    }

    &__number {
        color: $primary;
        font-weight: 900;
        font-size: 50px;
        z-index: 1;
        opacity: 0.5;
        margin-bottom: 0.75rem;
    }

    &__icon {
        width: 50px;
        height: auto;
        margin-bottom: 1rem;
    }

    &__title {
        position: relative;
        margin-top: 0;
        z-index: 2;
    }

    .btn {
        margin-top: auto;
    }

    @include bp(bp4) {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 3rem;
        margin: 4rem auto 8rem;

        &--1 {
            grid-template-columns: 1fr;
            max-width: 590px;
        }

        &__item {
            margin: 0;
            padding: 2rem;
        }

        &__number {
            position: absolute;
            top: 15px;
            left: -40px;
            color: $primary-light;
            font-weight: 900;
            font-size: 160px;
            z-index: 1;
            opacity: 0.5;
            margin-bottom: 0;
        }
    }
}
