/*------------------------------------*\
	Headings
\*------------------------------------*/

h1,
h2,
h3,
h4 {
	margin: 0;
	margin-bottom: .7em;
    color: $heading-color;
    font-family: $heading-font;
	line-height: 1.2em;
    text-rendering: optimizelegibility;

	small {
		display: block;
		color: #999;
		font-weight: 400;
	}
}

* + h1, * + h2, * + h3, * + h4, * + h5, * + h6, * + .h1, * + .h2, * + .h3, * + .h4, * + .h5, * + .h6 {
	margin-top: 1.4em;
}

h1, .h1 {
    font-weight: 900;
	@include font-size($h1-size - 4px);
	@include bp(bp3) {
		@include font-size($h1-size);
	}
}

h2, .h2 {
    font-weight: 900;
	@include font-size($h2-size - 4px);
	@include bp(bp3) {
		@include font-size($h2-size);
	}
}

.wrapper--full h2:first-child {
	margin-top: 0;
}

h3, .h3 {
    font-weight: 700;
	@include font-size($h3-size - 4px);
	@include bp(bp3) {
		@include font-size($h3-size);
	}
}

h4, .h4 {
    font-weight: 700;
	@include font-size($h4-size);
}
