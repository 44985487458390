/*------------------------------------*\
	Call out
\*------------------------------------*/

.call-out {
	background: $light-grey;
	// border: 2px solid #e9e9e9;
	padding: $spacing-l;
	margin: $spacing-l 0;
	border-radius: $border-radius;

	p:last-child {
		margin-bottom: 0;
	}
}