/*------------------------------------*\
	Typography
\*------------------------------------*/

$primary-font:		'Inter', 'Source Sans Pro', "Helvetica Neue", Helvetica, Arial, sans-serif;
$alt-font:          Georgia, Times, "Times New Roman", serif;
$heading-font:      $primary-font;

$base-font-size:    20px;
$base-line-height:  1.6em;

$h1-size:           38px;
$h2-size:           40px;
$h3-size:           30px;
$h4-size:           24px;
$h5-size:           22px;

$header-size:       56px;

$font-size-large:   28px;
$font-size-small:   18px;
$font-size-xsmall:  16px;
$font-size-xxsmall:  14px;
