/*------------------------------------*\
	Content
\*------------------------------------*/

.content {
    padding: 0 $spacing;
    margin-left: auto;
    margin-right: auto;
    max-width: $bp3;

    a:not(.btn) {
        color: $link-color;
        text-decoration: none;
        border-bottom: 2px solid $primary;
        transition: color 0.2s linear;  
        
        &:focus,
        &:hover {
            color: $primary;
            background: #f2ebff;
            text-decoration: none;
        }   
    } 

    ol {
        margin: 0;

        li {
            margin-top: 0;
        }
    }

    ul {
        list-style: none;
        padding: 0;

        li {
            position: relative;
            padding-left: 1.2rem;
            margin-bottom: 0.5em;
            margin-top: 0;
        }

        li p {
            display: inline;
        }

        li:before {
            content: "";
            position: absolute;
            top: 11px;
            left: 0px;
            height: 10px;
            width: 10px;
            background-image: url('../../images/bullet.svg');
            background-repeat: no-repeat;
            background-size: contain;
        }
    }

	ul.checkmarks {
        list-style: none;
        padding: 0;

		li {
			position: relative;
			padding-left: 1.8em;
			margin-bottom: 1em;
		}

		li:before {
			content: ' ';
			position: absolute;
			top: 5px;
			left: 0px;
			height: 22px;
			width: 22px;
			background-image: url('../../images/checkmark.svg');
			background-repeat: no-repeat;
			background-size: contain;
		}
	}

}



/*------------------------------------*\
	Responsive videos/images
\*------------------------------------*/
.content iframe {
    aspect-ratio: 16 / 9;
    width: 100%;
    height: auto;
}

.content img.aligncenter {
    display: block;
    width: 100%;
}



/*------------------------------------*\
	Section title block
\*------------------------------------*/
.section-title {
    margin-top: 3rem;

    &--header {
        margin-bottom: 2rem; 

        h1 {
            font-size: 30px;
            font-weight: 900;
            margin-bottom: 0.25em;

            @include bp(bp5) {
                font-size: 54px;
            }
        }

        h2 {
            margin-top: 0;
            font-size: 20px;
            font-weight: 400;

            @include bp(bp5) {
                font-size: 24px;
            }
        }
    }

    &--center {
        text-align: center;
    }

    &--hero {
        max-width: $bp5;
        text-align: center;
    }
}



/*------------------------------------*\
	Interlude block
\*------------------------------------*/
.interlude {
    position: relative;
    max-width: $bp4;
    margin: 1rem;
    padding: 0;
    background: white;
    border-radius: 40px;

    &__inner {
        padding: 1.5rem;
        margin: 0 auto;
        max-width: $bp3;
        z-index: 2;
    }

    &__icon {
        display: block;
        width: 50px;
        height: auto;
    }

    &__title {
        margin-top: 1em;
    }

    @include bp(bp3) {
        &__inner {
            padding: $spacing-l;
        }

        &__icon {
            width: 60px;
        }
    }

    @include bp(bp5) {
        margin: $spacing-xxl auto;

        &__inner {
            padding: $spacing-xxl $spacing;
        }
    }

    @include bp(bp7) {
        max-width: $bp5;

        &__icon {
            width: 120px;
            position: absolute;
            left: 60px;
        }

        &__title {
            margin-top: 0;
        }
    }
}



/*------------------------------------*\
	Blockquote block
\*------------------------------------*/
.blockquote {
	padding: 0 $spacing 0;
    margin: 2rem auto;
    max-width: $bp4;

    blockquote {
        position: relative;
        margin: 0;
        padding: 70px 0 0;
        border: 0;
        font-size: 20px;
        line-height: 1.4;

        @include bp(bp4) {
            font-size: $h4-size;
        }
    }

    blockquote:before {
        content:"";
        position: absolute;
        top: 0;
        left: 0;
        color: $primary;
        height: 50px;
        width: 50px;
        margin: 0;
        background: url('../../images/quotemarks.svg') no-repeat center;
        background-size: 100%;
    }

    p:last-child {
        margin-bottom: 1rem;
    }

    figcaption {
        margin: 0;
        font-weight: bold;
    }

    &__cite,
    cite {
        display: block;
        font-size: $font-size-small;
        font-style: normal;
        font-weight: bold;
        line-height: 1.4;
    }

    cite {
        font-weight: normal;
    }

    &__footer {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 0.5rem;
    }

    &__avatar {
        width: 60px;
        height: 60px;
        border-radius: 60px;
        border: 4px solid white;
    }

    &__cta {
        margin-top: 0;
        margin-left: 0;

        @include bp(bp4) {
            margin-left: auto;
        }
    }

    &__break {
        flex-basis: 100%;
        height: 0;

        @include bp(bp4) {
            display: none;
        }
    }
}

.blockquote--small {
    display: block;
    padding: 0 $spacing;
    margin: 2rem auto;
    max-width: $bp3;

    @include bp(bp4) {
        margin: 2rem auto;
    }
}

.swiper-slide .blockquote--small {
    max-width: calc($bp3 - 2rem);
}

.blockquote--small blockquote {
    padding-top: 50px;

    @include bp(bp4) {
        font-size: $h5-size;
    }
}

.blockquote--small blockquote:before {
    height: 40px;
    width: 40px;
}


/*------------------------------------*\
	CTA block
\*------------------------------------*/
.cta {
    position: relative;
    max-width: $bp4;
    margin: 3rem 1rem;
    background: $primary;
    border-radius: 40px;

    &__inner {
        padding: $spacing-l;
        margin: 0 auto;
        max-width: $bp3;
    }

    h2, p, a {
        color: white;
    }

    a {
        font-weight: bold;
    }

    &__link {
        display: flex;
        align-items: center;
        gap: 1rem;
    }

    .btn:hover {
        color: white;
        background: none;
        text-decoration: underline;
    }

    .avatar {
        height: 75px;
        width: 75px;
        border-radius: 50px;
        border: 4px solid white;
    }

    @include bp(bp5) {
        margin: 4rem auto;

        &__inner {
            padding: $spacing-xxl $spacing;
        }
    }
}



/*------------------------------------*\
	Lead magnet block
\*------------------------------------*/
.lead-magnet {
    position: relative;
    max-width: $bp4;
    margin: 3rem 1rem;
    background: $primary;
    border-radius: 40px;

    h2, p {
        color: white;
    }

    &__inner {
        padding: $spacing-l;
        margin: 0 auto;
        max-width: $bp3;
    }

    &__image {
        display: none;
        float: right;
    }

    @include bp(bp4) {
        margin: 4rem auto;

        &__inner {
            padding: $spacing-xxl $spacing;
        }

        &__image {
            display: block;
            margin-left: 20px;
        }
    }
}
