/*------------------------------------*\
	Horizontal rule
\*------------------------------------*/

hr {
    margin: 4rem 0;
    border: none;
    height: 3px;
    background: #ccced1;
}
