/*------------------------------------*\
	Buttons
    * default (purple button)
    * link (styled as a link with arrow)
    * arrow (styled as button with arrow)
\*------------------------------------*/

.btn {
    display: inline-block;
	margin: $spacing 0 0;
	padding: 1em 1.4em;
	border: 0;
	border-radius: $border-radius;
	background-color: $primary;
	color: #fff;
	text-decoration: none;
    text-align: left;
	font-family: $primary-font;
	font-weight: 700;
    line-height: 1;
	transition: all 0.2s linear;
	cursor: pointer;
    @include font-size(16px);

    svg {
        height: 14px;
        width: 14px;
        margin-left: 8px;
        transition: transform 0.3s ease-out;
    }
    
	@include bp(bp2) {
        @include font-size(18px);
	}

	&:hover, &:focus {
		outline: 0;
		background: $primary-dark;
		color: #fff;
		text-decoration: none;
	}

    &:hover svg {
        transform: translateX(4px);
    }
}

.btn--center {
    display: block;
    width: fit-content;
	margin: $spacing auto 0;
}

.btn--link {
    background: transparent;
    color: $font-color;
    padding: 0;
    border-radius: 0;
    border-bottom: 2px solid $primary;
	line-height: $base-line-height;
    margin-top: 0;

    &:hover {
        background: #f2ebff;
        color: $font-color;
    }
}
